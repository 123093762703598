import axios from "axios";
import { REPORT_API_CONTEXT_PATH } from "../apiContextPath";
import { generateTraceId } from "./util/AxiosInstanceUtil";

const reportAxiosInstance = axios.create({
    baseURL: REPORT_API_CONTEXT_PATH,
    timeout: Number(process.env.REACT_APP_CONNECTION_TIME_OUT) ?? 20000,
    headers: { "Content-Type": "application/json" }
})

reportAxiosInstance.interceptors.request.use((config) => {
    // Modify the config object to include the token in the headers
    if (config.headers) {
        config.headers.Authorization = `Bearer ${localStorage.getItem("access_token_with_refresh")}`;
        config.headers.BuToken = `${localStorage.getItem("access_bu_token")}`;
        config.headers["X-Trace-Id"] = generateTraceId();
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

reportAxiosInstance.defaults.timeout = Number(process.env.REACT_APP_CONNECTION_TIME_OUT) ?? 20000;

export default reportAxiosInstance;